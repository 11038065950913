import { reactive, defineComponent } from "vue";
import { useRouter } from "vue-router";
import routePath from "@/commons/RoutePath";
export default defineComponent({
    name: "ListIncentives",
    setup: function () {
        var router = useRouter();
        var state = reactive({
            listBreadcrumb: [{
                    route: "/",
                    name: "TOP"
                },
                {
                    route: "",
                    name: "ダッシュボード "
                }],
        });
        return {
            state: state,
            routePath: routePath
        };
    }
});
